import { Spinner, VegaIcon } from '@/components/icons';
import { useVendor } from '@/features/company/hooks/use-vendor';
import { updateFavicon, updateTitle } from '@/lib/utils';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BrandIcon } from '@/components/icons';
import { useVendorStyles } from '@/features/company/hooks/use-vendor-styles';

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  const [vendor, loading] = useVendor();
  const { search } = useLocation();
  const apiKeyPresent = new URLSearchParams(search).has('apiKey');
  apiKeyPresent? useVendorStyles(true) : useVendorStyles();

  useEffect(() => {
    if (vendor?.branding.favicon && vendor?.branding.webTitle) {
      updateFavicon(vendor.branding.favicon);
      updateTitle(vendor.branding.webTitle)
    } else {
      updateFavicon(
        'https://firebasestorage.googleapis.com/v0/b/vegabymypocketcounsel.appspot.com/o/assets%2Fmpc.png?alt=media&token=d1b7a7b4-8b66-47aa-9d52-e2552d0b8f92',
      );
    }
  }, [vendor]);



  return (
    <div className='flex min-h-screen bg-primary items-center justify-center [&_a]:text-info'>
      <div
        className='w-full max-w-md bg-white border-gray-300 rounded-lg shadow-lg px-4'
        style={{
          marginTop: '2rem',
          marginBottom: '1.5rem',
        }}
      >
        <div className='w-full mx-auto flex flex-col gap-10 py-8 px-4'>
          <div className='flex gap-3 justify-center items-center'>
            {loading || (apiKeyPresent && !vendor?.branding?.logoUrl) ? (
              <Spinner />
            ) : vendor?.branding?.logoUrl ? (
              <BrandIcon size='lg2' />
              // <img
              //   src={vendor.branding.logoUrl}
              //   alt='Vendor Logo'
              //   style={{ width: '100px', height: 'auto', objectFit: 'contain' }}
              // />
            ) : (
              <VegaIcon size='md' />
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
