import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * A utility function to update the favicon for 3rd party vendor authentication.
 * @param faviconUrl - The url of the vendor url.
 */
export const updateFavicon = (faviconUrl: string) => {
  const existingLink = document.querySelector("link[rel='icon']");
  if (existingLink) {
    existingLink.setAttribute('href', faviconUrl);
  } else {
    const newLink = document.createElement('link');
    newLink.rel = 'icon';
    newLink.href = faviconUrl;
    document.head.appendChild(newLink);
  }
};

/**
 * A utility function to update the web app title for 3rd party vendor authentication.
 * @param title - The title of the vendor's web app or the provided title.
 */
export const updateTitle = (title: string) => {
  document.title = title;
};

/**
 * A utility function to merge Tailwind classes with clsx.
 * @param inputs - The Tailwind classes to merge.
 * @returns The merged classes.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Return a name in format "Joshua O."
 * @param name
 * @returns
 */
export function getInitials(name: string) {
  const [first, last] = name.split(' ');
  if (!last) return first;
  return `${first} ${last[0]}.`;
}

/**
 * A utility function to create a unique key for react components.
 * @param prefix - The prefix to use for the key.
 */
export function createKey(prefix: string) {
  return `${prefix}-${Math.random().toString(36).substr(2, 9)}`;
}

/**
 * A utility function to merge multiple refs.
 * @param refs - The refs to merge.
 * @returns The merged refs.
 */
export function mergeRefs<T>(...refs: Array<React.Ref<T> | undefined>) {
  return (value: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}

export const convertSlugToTitle = (slug: string) => {
  return slug
    .split('-')
    .map((word) => (word ? word[0].toUpperCase() + word.slice(1) : ''))
    .join(' ');
};
/**
 * A utility function to generate a random password for users.
 * @param  -length of password and passwrod conidtions.
 * @deprecated
 * @returns random password.
 */
export function generatePassword(
  length: number,
  includeUppercase: boolean,
  includeNumbers: boolean,
  includeSpecialChars: boolean,
) {
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numberChars = '0123456789';
  const specialChars = '!@#$%^&*()_-+=<>?/[]{}';
  let validChars = lowercaseChars;
  if (includeUppercase) {
    validChars += uppercaseChars;
  }
  if (includeNumbers) {
    validChars += numberChars;
  }
  if (includeSpecialChars) {
    validChars += specialChars;
  }
  let password = '';
  const validCharsLength = validChars.length;
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * validCharsLength);
    password += validChars.charAt(randomIndex);
  }
  return password;
}
/**
 * A utility function for formatting date
 * @param date - The date to format.
 * @returns The formatted date.
 */
export const formatDate = (date?: string | number | Date) => {
  return new Date(date || '').toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const getCompanyInitials = (name?: string) => {
  if (!name) return '';
  const [first, second] = name.split(' ');
  if (!second) return `${first?.charAt(0)}`;
  return `${first?.charAt(0)}${second?.charAt(0)}`;
};

export const getDurationFromNow = (duration: string | number) => {
  const date = new Date(duration);
  const now = Date.now();

  // return few seconds ago, minutes ago, hours ago, days ago, weeks ago, months ago, years ago where applicable

  const seconds = Math.floor((now - date.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(seconds / 3600);
  const days = Math.floor(seconds / 86400);

  if (seconds <= 0) {
    return 'just now';
  }

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  }

  if (minutes < 60) {
    return `${minutes} minutes ago`;
  }

  if (hours < 24) {
    return `${hours} hours ago`;
  }

  if (days < 7) {
    return `${days} days ago`;
  }
};

export function isEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

export const getCurrencySign = (country: string | undefined): string => {
  if (country === 'Kenya') {
    return 'KES';
  } else if (country === 'Nigeria') {
    return '₦';
  } else if (country === 'South Africa') {
    return 'R';
  } else {
    return 'USD';
  }
};

export const getCurrencySignCustomPlan = (country: string | undefined): string => {
  if (country === 'Nigeria') {
    return '₦';
  } else if (country === 'South Africa') {
    return 'ZAR';
  } else {
    return 'USD';
  }
};

export const convertKebaCaseToLowerCase = (kebabCase: string) => {
  return kebabCase
    .split('-')
    .map((word) => word.toLowerCase())
    .join(' ');
};

export const findObjectByCondition = <T>(
  collection: Record<string, T>,
  condition: (obj: T) => boolean,
): T | null => {
  if (!collection) return null;
  const matchingObject = Object.values(collection).find(condition);
  return matchingObject || null;
};

export const getFileExtension = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return ''; // No file extension found
  }
  return fileName.slice(lastDotIndex + 1);
};

export const ensureArray = (value: string | string[] | undefined): string[] => {
  if (!value) return [];
  return Array.isArray(value) ? value : [value];
};
