import { useEffect } from 'react';
import { useVendor } from './use-vendor';

/**
 * Set vendor styles on the document root
 */
export const useVendorStyles = (clear?:boolean) => {
  const [vendor] = useVendor();

  useEffect(() => {
    let style = {
      '--primary': !clear? '' : 'white', //clear returns a white background isntead of the lawyered up bg during the redirect
      '--primary-foreground': '',
      '--sidebar-accent-foreground': '',
      // 'secondary': '',
      // 'secondary-foreground':
    };

    if (vendor) {
      style = {
        '--primary': vendor.branding.primaryColor,
        '--primary-foreground': '255 255 255',
        '--sidebar-accent-foreground': vendor.branding.primaryColor,
        // 'secondary': vendor.branding.secondaryColor,
        // 'secondary-foreground': '255 255 255',
      };
    }

    Object.entries(style).forEach(([key, value]) => {
      if (value) {
        document.documentElement.style.setProperty(key, value);
      } else {
        document.documentElement.style.removeProperty(key);
      }
    });
  }, [vendor]);
};
