import { Sidebar, SidebarContent, SidebarGroup, SidebarGroupContent, SidebarGroupLabel, SidebarHeader, SidebarMenu, SidebarMenuButton, SidebarMenuItem, SidebarMenuSub, SidebarMenuSubItem, useSidebar } from '@/components/ui/sidebar';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { ChevronDown, XIcon } from 'lucide-react';
import { BrandIcon } from './icons';
import { Button } from './ui';
import { cn } from '@/lib/utils';
import { forwardRef } from 'react';
import { useVendor } from '@/features/company/hooks/use-vendor';

type AppSidebarItemData = LinkProps & {
  id: string;
  icon: React.FC<any>;
  name: string;
  target?: string;
};

type AppSidebarItemGroupData = {
  id: string;
  icon: React.FC<any>;
  name: string;
  items: AppSidebarItemData[];
};

type AppSidebarItem = AppSidebarItemData | AppSidebarItemGroupData;

const normalizePath = (path: string) => {
  return path.endsWith('/') ? path : `${path}/`;
};

const isCurrentRoute = (item: AppSidebarItem, pathname = window.location.pathname): boolean => {
  if ('to' in item) {
    const path = normalizePath(pathname);
    const itemRoute = normalizePath(item.to as string);
    if (itemRoute === '/') {
      return path === '/';
    }
    return path.startsWith(itemRoute);
  }

  return item.items.some((subItem) => isCurrentRoute(subItem));
};

const useItemRoute = (item: AppSidebarItem) => {
  const { pathname } = useLocation();
  const isActive = isCurrentRoute(item, pathname);

  return { isActive };
};

const useAppSidebar = useSidebar;

const AppSidebar = Sidebar;

const AppSidebarContent = SidebarContent;

const AppSidebarHeader = forwardRef<
  HTMLDivElement,
  React.ComponentProps<'div'>
>(({ className, children, ...props }, ref) => {
  const { isMobile, toggleSidebar } = useSidebar();
  const [vendor] = useVendor();

  return (
    <SidebarHeader ref={ref} {...props} className={cn('h-[var(--header-height)] flex flex-row justify-between items-center', className)}>
      {children ?? (
        <>
         { vendor ? <BrandIcon size='lg2' /> : <BrandIcon size='sm' />}
          {isMobile && (
            <Button
              data-sidebar="trigger"
              variant="ghost"
              size="icon"
              className='h-6 w-6'
              onClick={() => toggleSidebar()}
            >
              <XIcon />
              <span className="sr-only">Close Sidebar</span>
            </Button>
          )}
        </>
      )}
    </SidebarHeader>
  );
});
AppSidebarHeader.displayName = 'AppSidebarHeader';

const AppSidebarMenuItem = ({ item }: { item: AppSidebarItemData }) => {
  const { name, icon: Icon, ...props } = item;
  const { isMobile, toggleSidebar } = useSidebar();
  const { isActive } = useItemRoute(item);

  return (
    <SidebarMenuItem>
      <SidebarMenuButton asChild isActive={isActive} onClick={() => isMobile && toggleSidebar()}>
        <Link {...props}>
          <Icon />
          <span>{name}</span>
        </Link>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
};

const AppSidebarSubMenuItem = ({ item }: { item: AppSidebarItemData }) => {
  const { name, icon: Icon, ...props } = item;
  const { isMobile, toggleSidebar } = useSidebar();
  const { isActive } = useItemRoute(item);

  return (
    <SidebarMenuItem>
      <SidebarMenuButton className="data-[active=]:" asChild isActive={isActive} onClick={() => isMobile && toggleSidebar()}>
        <Link {...props}>
          <Icon />
          <span>{name}</span>
        </Link>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
};

const AppSidebarSubMenu = ({ group }: { group: AppSidebarItemGroupData }) => {
  const { isActive } = useItemRoute(group);

  return (
    <Collapsible defaultOpen className="group/collapsible">
      <SidebarMenuItem>
        <CollapsibleTrigger asChild>
          <SidebarMenuButton isActive={isActive}>
            <group.icon />
            <span>{group.name}</span>
            <ChevronDown className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-180" />
          </SidebarMenuButton>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <SidebarMenuSub>
            <SidebarMenuSubItem />
            {group.items.map((item) => (
              <AppSidebarSubMenuItem key={item.id} item={item} />
            ))}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  );
};

const AppSidebarMenu = ({ items }: { items: AppSidebarItem[] }) => {
  return (
    <SidebarMenu>
      {items.map((item) => {
        if ((item as AppSidebarItemGroupData).items) {
          return <AppSidebarSubMenu key={item.id} group={item as AppSidebarItemGroupData} />;
        }
        return <AppSidebarMenuItem key={item.id} item={item as AppSidebarItemData} />;
      })}
    </SidebarMenu>
  );
};

const AppSidebarCollapsibleGroup = ({ title, children, className }: { title: string; children: React.ReactNode, className?: string }) => (
  <Collapsible defaultOpen className={cn('group/collapsible', className)}>
    <SidebarGroup>
      <SidebarGroupLabel>
        <CollapsibleTrigger className='flex justify-between w-full'>
          {title}
          <ChevronDown className="ml-auto w-4 h-4 transition-transform group-data-[state=open]/collapsible:rotate-180" />
        </CollapsibleTrigger>
      </SidebarGroupLabel>
      <CollapsibleContent>
        <SidebarGroupContent>
          {children}
        </SidebarGroupContent>
      </CollapsibleContent>
    </SidebarGroup>
  </Collapsible>
);

const AppSidebarGroup = ({ title, children, className }: { title: string; children: React.ReactNode, className?: string }) => (
  <SidebarGroup className={className}>
    <SidebarGroupLabel>
      {title}
    </SidebarGroupLabel>
    <SidebarGroupContent>
      {children}
    </SidebarGroupContent>
  </SidebarGroup>
);

export {
  AppSidebar,
  AppSidebarContent,
  AppSidebarHeader,
  AppSidebarMenu,
  AppSidebarGroup,
  AppSidebarCollapsibleGroup,
  AppSidebarMenuItem,
  AppSidebarSubMenu,
  AppSidebarSubMenuItem,
  isCurrentRoute,
  useItemRoute,
  useAppSidebar,
};

export type {
  AppSidebarItemData,
  AppSidebarItemGroupData,
};
