import * as React from 'react';
import { cn } from '@/lib/utils';
import { useControllableState } from '@/lib/hooks/state';
import ReactContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import DOMPurify from 'dompurify';

export const sanitizeInput = (input: string): string => {
  const sanitized = DOMPurify.sanitize(input, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
  const decoded = new DOMParser()
    .parseFromString(sanitized, 'text/html')
    .documentElement?.textContent || '';
  return decoded;
};

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  description?: string;
  error?: string;
  required?: boolean;
}

const Wrapper: React.FC<
  React.PropsWithChildren & {
    label?: string;
    description?: string;
    id?: string;
    error?: string;
  }
> = ({ id, label, description, error, children }) => {
  return (
    <div className='flex flex-col gap-2' data-error={error ? 'true' : 'false'}>
      {label && (
        <label htmlFor={id} className='text-sm font-normal text-muted-foreground'>
          {label}
        </label>
      )}
      {description && (
        <small className='text-sm font-normal text-muted-foreground'>{description}</small>
      )}
      <div className='flex flex-col gap-1'>
        {children}
        {error && <small className='text-sm font-normal text-[tomato]'>{error}</small>}
      </div>
    </div>
  );
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ id, className, type = 'text', label, error, required, onChange, value, ...props }, ref) => {
    const rId = React.useId();
    const labelId = id || rId;

    const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      const sanitizedValue = sanitizeInput(target.value);

      // we only update the input's value if it's different after sanitization
      if (target.value !== sanitizedValue) {
        target.value = sanitizedValue;
      }

      // here we call the onChange prop with the sanitized value if it exists
      if (onChange) {
        const syntheticEvent = {
          ...event,
          target: {
            ...target,
            value: sanitizedValue,
          },
        };
        onChange(syntheticEvent);
      }
    };

    // Simple validation check for required fields
    const isValid = required ? !!value && typeof value === 'string' && value.trim() !== '' : true;

    return (
      <Wrapper label={label!} id={labelId} error={!isValid ? error : undefined}>
        <input
          id={labelId}
          type={type}
          className={cn(
            'h-10 w-full rounded-xl border border-input bg-transparent px-4 text-sm text-left shadow-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:opacity-50 disabled:bg-input/20',
            className,
            { 'border-red-500': !isValid }, // Apply red border if invalid
          )}
          ref={ref}
          onInput={handleInput} // Use onInput to handle sanitization in real-time
          value={value}
          {...props}
        />
        {error && <p className='text-red-500 text-sm'>{error}</p>}
      </Wrapper>
    );
  },
);

Input.displayName = 'Input';

export interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  description?: string;
  error?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ id, className, label, error, onChange, value, ...props }, ref) => {
    const rId = React.useId();
    const labelId = id || rId;

    const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
      const target = event.target as HTMLTextAreaElement;
      const sanitizedValue = sanitizeInput(target.value);

      // Update the textarea's value only if it differs after sanitization
      if (target.value !== sanitizedValue) {
        target.value = sanitizedValue; // Reflect the sanitized value in the textarea
      }

      // Call onChange with the sanitized value if it exists
      if (onChange) {
        const syntheticEvent = {
          ...event,
          target: {
            ...target,
            value: sanitizedValue,
          },
        };
        onChange(syntheticEvent);
      }
    };

    return (
      <Wrapper label={label!} id={labelId} error={error}>
        <textarea
          id={labelId}
          onInput={handleInput}
          value={value}
          {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
          className={cn(
            'flex min-h-[60px] w-full rounded-xl border border-input bg-transparent px-3 py-2 text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground/50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          ref={ref}
        />
      </Wrapper>
    );
  },
);

Textarea.displayName = 'Textarea';

type ContentEditableProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  value: string;
  onValueChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
};

const ContentEditable = React.forwardRef<HTMLDivElement, ContentEditableProps>(
  ({ value, onValueChange, placeholder, disabled, ...props }, ref) => {
    const [html, setHtml] = useControllableState({
      prop: value,
      onChange: onValueChange,
      defaultProp: '',
    });

    const handleChange = React.useCallback(
      (event: ContentEditableEvent) => {
        const sanitizedValue = sanitizeInput(event.target.value); // Use sanitizeInput to sanitize
        setHtml(sanitizedValue);
        onValueChange(sanitizedValue); // Update the parent component with sanitized value
      },
      [onValueChange],
    );

    return (
      <ReactContentEditable
        {...props}
        tagName='div'
        innerRef={ref as React.RefObject<HTMLDivElement>}
        contentEditable={!disabled}
        suppressContentEditableWarning
        style={
          {
            '--placeholder': placeholder,
          } as React.CSSProperties
        }
        className={cn(
          'w-full rounded-xl border border-input bg-transparent px-6 py-2.5 text-sm shadow-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50',
          props.className,
        )}
        onChange={handleChange}
        html={html ?? ''}
      />
    );
  },
);

ContentEditable.displayName = 'ContentEditable';

export { Input, Textarea, ContentEditable };
export type { ContentEditableProps };
