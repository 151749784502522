import { Button, Modal, withModal } from '../../../../../components/ui';
import { VegaFile } from '@/lib/definitions';
import { useUser } from '@/providers/user';
import { useForm } from 'react-hook-form';
import { usePromise } from '@/lib/hooks';
import { Lock } from 'lucide-react';
import { MembersFormField } from '../../members-form-field';
import { updateFileAccess } from '@/features/company/actions/files';
import { useFile } from '@/features/company/hooks/use-files';
import { useCompany } from '@/features/company/providers/company';

type ManageFileAccessFormProps = {
  file: VegaFile;
  onClose: () => void;
};

interface DTO {
  users: string[];
}

const ManageFileAccessForm = ({ file, onClose }: ManageFileAccessFormProps) => {
  const { user } = useUser('ManageFileAccessForm');
  const { activeCompany } = useCompany('ManageFileAccessForm');

  const form = useForm<DTO>({
    defaultValues: {
      users: file.accessBy ?? Object.keys(activeCompany?.members) ?? [],
    },
  });

  const [changeAccess, isChangingAccess] = usePromise(
    form.handleSubmit(async (data) => {
    await updateFileAccess(file.id, data.users);
      onClose();
    }),
  );

  const isSelf = (id: string) => id === user.uid;

  return (
    <form onSubmit={changeAccess}>
      <Modal.Body>
        <div className='space-y-4'>
          <div>
            <h4 className='font-medium text-base'>Choose who can access this file</h4>
            <Modal.Description>
              If a user is not selected, access will be given to all team member.
            </Modal.Description>
          </div>
          <div>
            <MembersFormField
              includeRole
              name='users'
              control={form.control}
              isDisabled={isSelf}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='flex justify-end space-x-2 mt-4 sticky bottom-0 bg-background py-3 border-t border-muted'>
        <Modal.Close asChild>
          <Button size='lg' variant='outline'>
            Cancel
          </Button>
        </Modal.Close>
        <Button size='lg' type='submit' loading={isChangingAccess}>
          Save
        </Button>
      </Modal.Footer>
    </form>
  );
};

type ManageFileAccessModalProps = {
  fileId: string;
};

export const ManageFileAccessModal = withModal<ManageFileAccessModalProps>(
  ({ fileId, onOpenChange }) => {
    const { data: file, status } = useFile('ManageFileAccessModal', fileId);

    return (
      <Modal.Content className='max-w-lg'>
        <Modal.Header>
          <Modal.Title className='flex gap-4 items-center'>
            <Lock width={24} height={24} className='text-primary' />
            <span>Manage File Access</span>
          </Modal.Title>
        </Modal.Header>
        {status === 'loading' ? (
          <Modal.Body>
            <div>Loading...</div>
          </Modal.Body>
        ) : status === 'error' ? (
          <Modal.Body>
            <div>Error ...</div>
          </Modal.Body>
        ) : file ? (
          <ManageFileAccessForm file={file} onClose={() => onOpenChange(false)} />
        ) : null}
      </Modal.Content>
    );
  },
);
