import { Draft } from '@/lib/definitions';
import * as F from '@/lib/firebase';
import { ref, remove, update } from 'firebase/database';
import { httpsCallable } from 'firebase/functions';

export const deleteAgreement = async (companyId: string, agreementId: string) => {
  await remove(ref(F.db, `drafts/${companyId}/${agreementId}`));
};

export const renameDraft = async (companyId: string, agreementId: string, name: string) => {
  await update(ref(F.db, `drafts/${companyId}/${agreementId}`), { name });
};

export const exportDrafts = async (companyId: string) => {
  const getAnalytics = httpsCallable(F.functions, 'analytics-exportDrafts');
  const result = await getAnalytics({ companyId });
  return result;
};

export const updateAgreementStatus = async (
  companyId: string,
  agreementId: string,
  status: string,
) => {
  const agreementRef = ref(F.db, `drafts/${companyId}/${agreementId}`);
  await update(agreementRef, { status } as Partial<Draft>);
};
