import { Outlet, RouteObject } from 'react-router-dom';
import { lazyImport } from '@/lib/utils';
import { AuthLayout } from '@/features/auth/components/auth-layout';
import { Toaster } from '@/providers/toaster';
import { useVendor } from '@/features/company/hooks/use-vendor';
import { useEffect} from 'react';
import * as F from '@/lib/firebase';

const { ForgotPassword } = lazyImport(() => import('@/features/auth/pages'), 'ForgotPassword');
const { Register } = lazyImport(() => import('@/features/auth/pages'), 'Register');
const { Login } = lazyImport(() => import('@/features/auth/pages'), 'Login');
const { VerifyEmail } = lazyImport(() => import('@/features/auth/pages'), 'VerifyEmail');
const { FirebaseAction } = lazyImport(() => import('@/features/auth/pages'), 'FirebaseAction');
const { VendorUsersAuth } = lazyImport(() => import('@/features/auth/pages'), 'VendorUsersAuth');

const VendorGuard = ({ children }: { children: React.ReactNode }) => {
  const [vendor] = useVendor();
  const isLoggedIn = !!F.auth.currentUser;

  useEffect(() => {
    if (vendor && !isLoggedIn && vendor?.branding?.homeUrl) {
      window.location.replace(vendor.branding.homeUrl);
    }
  }, [isLoggedIn, vendor]);

  // if (!isLoggedIn) {
  //   return <div>Loading...</div>; // Loader while checking auth state
  // }

  return <>{children}</>;
};

export const authRoutes: RouteObject[] = [
  {
    element: (
      <AuthLayout>
        <VendorGuard>
          <Outlet />
        </VendorGuard>
        <Toaster />
      </AuthLayout>
    ),
    children: [
      { path: '/login', element: <Login /> },
      { path: '/signup', element: <Register /> },
      { path: '/forgot-password', element: <ForgotPassword /> },
      { path: '/verify', element: <VerifyEmail /> },
      { path: '/firebase-action', element: <FirebaseAction /> },
      {
        path: '/authentication',
        element: <VendorUsersAuth />,
      },
    ],
  },
];
