import { UserCredential, signOut } from 'firebase/auth';
import { get, ref, set } from 'firebase/database';
import * as F from '@/lib/firebase';
import { User } from '@/lib/definitions';
import { clearPreloadables } from '@/lib/reactfire';

export const createUserFromCredentials = async (credentials: UserCredential, name?: string) => {
  const userRef = ref(F.db, `users/${credentials.user.uid}`);
  const userSnapshot = await get(userRef);
  if (userSnapshot.exists()) return;

  set(userRef, {
    uid: credentials.user.uid,
    email: credentials.user.email,
    fullName: credentials.user.displayName ?? name ?? credentials.user.email?.split('@')[0],
  } as User);
};

export const logoutUser = async (logoutRedirectUrl: string, vendor?: boolean) => {
  try {
    if (vendor) {
      window.location.replace(logoutRedirectUrl);
      // Add a slight delay to ensure the redirect is initiated before signing out
      setTimeout(async () => {
        // Sign out of Firebase in the background
        await signOut(F.auth);

        clearPreloadables();
      }, 1500);
    } else {
      await signOut(F.auth);
      window.location.replace(logoutRedirectUrl);
      clearPreloadables();
    }
  } catch (error) {
    console.error('Error during logout:', error);
    window.location.replace('/login');
  }
};
