import { useState } from 'react';
import { Button, Modal, withModal } from '../../../../../components/ui';
import { VegaFile } from '@/lib/definitions';
import { useUser } from '@/providers/user';
import { useForm } from 'react-hook-form';
import { usePromise } from '@/lib/hooks';
import { Waypoints, Lock, MailCheck, CandlestickChart } from 'lucide-react';
import { MembersFormField } from '../../members-form-field';
import { updateFileAccess } from '@/features/company/actions/files';
import { useFileAccess } from '@/features/company/hooks/use-files';
import { useFile } from '@/features/company/hooks/use-files';
import { useCompany } from '@/features/company/providers/company';
import { StatusPill } from '../../status';
import { StatusSelectorDropdown } from '../StatusSelectorDropDown';
import { updateStatusForBoth } from '@/actions/general';
import { shareFileInternally } from '@/features/company/actions/files';
import { useStatuses } from '@/features/company/hooks/use-statuses';


type ShareFileInternallyFormProps = {
  file: VegaFile;
  onClose: () => void;
  draftId?: string;
  statusId?: string;
};

interface DTO {
  users: string[];
  notificationUsers: string[];
  status: string;
}

const ShareFileInternallyForm = ({
  file,
  onClose,
  draftId,
  statusId,
}: ShareFileInternallyFormProps) => {
  const { user } = useUser('ShareFileInternallyForm');
  const { activeCompany } = useCompany('ShareFileInternallyForm');
  const { getStatus } = useStatuses('ShareFileInternallyForm');

  const [currentStatus, setCurrentStatus] = useState(statusId ?? file.status);

  const form = useForm<DTO>({
    defaultValues: {
      users: file.accessBy ?? Object.keys(activeCompany?.members) ?? [],
      notificationUsers: [],
      status: statusId ?? file.status,
    },
  });

  const { isTraverseOnly } = useFileAccess('FilesTable', file?.id);

  const handleStatusSelect = async (newStatusId: string) => {
    setCurrentStatus(newStatusId); // Update the local state immediately

    try {
      const fileId = file?.id;
      const agreementId = draftId ?? null;
      await updateStatusForBoth(
        activeCompany?.id,
        fileId,
        agreementId as string,
        newStatusId,
        'file',
      );
      //   console.log('Status updated successfully.');
    } catch (error) {
      console.error('Failed to update status:', error);
      // Optionally revert to the previous status if the update fails
    }
  };

  const [changeAccess, isChangingAccess] = usePromise(
    form.handleSubmit(async (data) => {
      const initialUsers = form.getValues('users'); // Get default values for users
      const newUsers = data.users;

      // Compare the initial users and new users
      const hasChanges = JSON.stringify(initialUsers.sort()) !== JSON.stringify(newUsers.sort());

      if (hasChanges) {
        console.log('Changes detected. Updating file access...');
        await updateFileAccess(file.id, newUsers);
      } else {
        console.log('No changes detected. Skipping file access update.');
      }

    // Validation: Ensure there are users to notify
    if (!data.notificationUsers || data.notificationUsers.length === 0) {
        form.setError('notificationUsers', {
          type: 'manual',
          message: 'Please include at least one user to notify.',
        });
        return;
      }

      const status = getStatus(form.getValues('status')).label;

      //get targetEmails

      const userIds = data.notificationUsers ?? [];
      const companyMembers = activeCompany.members ?? {};
      const targetEmails = userIds
        .flatMap((id) => {
          if (id.startsWith('role__')) {
            const roleId = id.replace('role__', '');
            return Object.values(companyMembers)
              .filter((member) => member.role === roleId)
              .map((member) => member.email);
          } else {
            const member = companyMembers[id];
            return member?.email ? [member.email] : [];
          }
        })
        .filter(Boolean);

      await shareFileInternally(file.name, file.id, targetEmails, status);
      onClose();
    }),
  );

  const isSelf = (id: string) => id === user.uid;

  return (
    <form onSubmit={changeAccess}>
      <Modal.Body>
        <div className='space-y-4'>
          {/* The notification section */}
          <div>
            <h4 className='font-medium text-base flex'>
              Select who should get notified{' '}
              <MailCheck width={20} height={20} className='text-primary ml-2' />
            </h4>
            <Modal.Description>
              These are the team members that will be notified of the document being shared.
            </Modal.Description>
            {form.formState.errors.notificationUsers && (
    <p className="text-red-500 text-sm mt-1">
      {form.formState.errors.notificationUsers.message}
    </p>
  )}
          </div>
          <div>
            <MembersFormField includeRole name='notificationUsers' control={form.control} />
          </div>

          {/* The status section */}
          <div>
            <h4 className='font-medium text-base flex'>
              Update the file status{' '}
              <CandlestickChart width={20} height={20} className='text-primary ml-2' />
            </h4>
            <Modal.Description>This is the status of the document.</Modal.Description>
          </div>
          <div>
            <StatusSelectorDropdown onSelect={handleStatusSelect}>
              <StatusPill statusId={currentStatus} disabled={isTraverseOnly} />
            </StatusSelectorDropdown>
          </div>

          {/* The access section */}
          <div>
            <h4 className='font-medium text-base flex'>
              Choose who can access this file
              <Lock width={20} height={20} className='text-primary ml-2' />
            </h4>
            <Modal.Description>
              These are the team members that have ACCESS to the file.
            </Modal.Description>
          </div>
          <div>
            <MembersFormField includeRole name='users' control={form.control} isDisabled={isSelf} />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className='flex justify-end space-x-2 mt-4 sticky bottom-0 bg-background py-3 border-t border-muted'>
        <Modal.Close asChild>
          <Button size='lg' variant='outline'>
            Cancel
          </Button>
        </Modal.Close>
        <Button size='lg' type='submit' loading={isChangingAccess}>
          Save
        </Button>
      </Modal.Footer>
    </form>
  );
};

type ShareFileInternallyProps = {
  fileId: string;
  draftId?: string;
  statusId?: string;
};

export const ShareFileInternally = withModal<ShareFileInternallyProps>(
  ({ fileId, onOpenChange, draftId, statusId }) => {
    const { data: file, status } = useFile('ShareFileInternally', fileId);

    return (
      <Modal.Content className='max-w-lg'>
        <Modal.Header>
          <Modal.Title className='flex gap-4 items-center'>
            <Waypoints width={24} height={24} className='text-primary' />
            <span>Share File to your team member</span>
          </Modal.Title>
        </Modal.Header>
        {status === 'loading' ? (
          <Modal.Body>
            <div>Loading...</div>
          </Modal.Body>
        ) : status === 'error' ? (
          <Modal.Body>
            <div>Error ...</div>
          </Modal.Body>
        ) : file ? (
          <ShareFileInternallyForm
            file={file}
            onClose={() => onOpenChange(false)}
            draftId={draftId}
            statusId={statusId}
          />
        ) : null}
      </Modal.Content>
    );
  },
);
