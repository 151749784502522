import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown';
import { PlusIcon } from '@radix-ui/react-icons';
import { useModal, usePromise } from '@/lib/hooks';
import { CustomStatusModal } from './CustomStatusModal';
import { PropsWithChildren } from 'react';
import { useStatuses } from '../../hooks/use-statuses';

type StatusSelectorProps = PropsWithChildren<{
  onSelect?: (status: string) => void;
}>;

export const StatusSelectorDropdown = (props: StatusSelectorProps) => {
  const { modal, modalProps, openModal } = useModal<'custom-status'>();

  const { statuses } = useStatuses('StatusSelectorDropdown');

  const [updateStatus, isUpdating] = usePromise(async (newStatus: string) => {
    props.onSelect?.(newStatus);
  });

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>{props?.children}</DropdownMenuTrigger>
        <DropdownMenuContent align='start'>
          {statuses?.map((status) => (
            <DropdownMenuItem
              key={status.id}
              onSelect={() => updateStatus(status.id)}
              disabled={isUpdating}
              className='flex items-center gap-4'
            >
              <div style={{ backgroundColor: status.color }} className='w-5 h-5 rounded-md' />
              <span className='capitalize'>{status.label}</span>
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem onSelect={() => openModal('custom-status')}>
            <PlusIcon className='mr-2 h-4 w-4' />
            Add new status
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {modal?.name == 'custom-status' && (
        <CustomStatusModal {...modalProps('custom-status')} onAdd={props.onSelect} />
      )}
    </>
  );
};
