import { updateAgreementStatus } from '@/actions/draft';
import { updateFileStatus } from '@/features/company/actions/files';

export const updateStatusForBoth = async (
  companyId: string,
  id: string,
  additionalUpdateId: string | null | undefined,
  status: string,
  type: 'agreement' | 'file',
) => {
  try {
    if (type === 'agreement') {
      // Update agreement status
      await updateAgreementStatus(companyId, id, status);

      // Update corresponding file status if additionalUpdateId exists
      if (additionalUpdateId) {
        await updateFileStatus(companyId, additionalUpdateId, status);
      } else {
        console.warn(`Skipped file update: No additionalUpdateId provided for agreement ${id}`);
      }
    } else if (type === 'file') {
      // Update file status
      await updateFileStatus(companyId, id, status);

      // Update corresponding agreement status if additionalUpdateId exists
      if (additionalUpdateId) {
        await updateAgreementStatus(companyId, additionalUpdateId, status);
      } else {
        console.warn(`Skipped agreement update: No additionalUpdateId provided for file ${id}`);
      }
    }
  } catch (error) {
    console.error('Error updating status:', error);
  }
};
