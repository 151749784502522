import { useCompany } from '@/features/company/providers/company';
import { Notification } from '@/lib/definitions';
import { useUser } from '@/providers/user';
import { ref, increment, update } from 'firebase/database';
import { useCallback } from 'react';
import { useDatabase, useDatabaseObjectData } from 'reactfire';

export const useNotifications = (consumerName: string) => {
  const { user } = useUser(consumerName);
  const { activeCompany: company } = useCompany(consumerName);

  const db = useDatabase();

  const notificationRef = ref(db, `notifications/${company?.id}/${user?.uid}`);
  const { data, ...response } = useDatabaseObjectData<Notification>(notificationRef);

  const readItem = useCallback(
    async (notificationId: string) => {
      const updates = {} as Record<string, any>;
      updates[`notifications/${company?.id}/${user?.uid}/notifications/${notificationId}/read`] =
        true;
      updates[`notifications/${company?.id}/${user?.uid}/unreadCount`] = increment(-1);

      try {
        await update(ref(db), updates);
      } catch (error) {
        console.error('Error marking notification as read:', error);
      }
    },
    [company?.id, user?.uid, db],
  );
  const cleanUp = useCallback(async () => {
    if (!data?.notifications || Object.keys(data.notifications).length === 0) {
      return;
    }

    const updates = {} as Record<string, any>;
    const readNotifications = Object.values(data.notifications).filter(
      (notification) => notification.read,
    );

    readNotifications.forEach((notification) => {
      updates[`notifications/${company?.id}/${user?.uid}/notifications/${notification.id}`] = null;
    });

    if (Object.keys(updates).length > 0) {
      try {
        await update(ref(db), updates);
      } catch (error) {
        console.error('Error during cleanup:', error);
      }
    } else {
      console.log('No read notifications to remove');
    }
  }, [data?.notifications, company?.id, user?.uid, db]);

  return {
    ...response,
    data: Object.values(data?.notifications ?? {}),
    unreadCount: data?.unreadCount ?? 0,
    readItem,
    cleanUp,
  };
};
