// import { VegaIcon } from '../icons';
import { cn } from '@/lib/utils';
import { Button } from '../ui';
import { ChevronLeftIcon } from '@radix-ui/react-icons';
import { GoBack } from '../../features/company/components';
import { BrandIcon } from '../icons';

export type PlainLayoutProps = React.HTMLAttributes<HTMLDivElement> & {
  goBack?: boolean;
};

export const PlainLayout = ({ children, className, goBack }: PlainLayoutProps) => {
  return (
    <main className='space-y-16'>
      <header className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-24 flex gap-3 justify-center items-center'>
        {goBack && (
          <GoBack asChild>
            <Button variant='mid' className='text-foreground'>
              <ChevronLeftIcon width={24} height={24} />
              Back
            </Button>
          </GoBack>
        )}
        <div className='flex-1 flex justify-center items-center'>
          {/* <VegaIcon size='md' /> */}
          <BrandIcon size='lg'/> 
        </div>
        {goBack && (
          <GoBack asChild>
            <Button variant='mid' className='text-foreground invisible'>
              <ChevronLeftIcon width={24} height={24} />
              Back
            </Button>
          </GoBack>
        )}
      </header>
      <section
        className={cn('max-w-3xl mx-auto px-4 sm:px-6 lg:px-8', className)}
        style={{ '--min-available-height': '10rem' } as any}
      >
        {children}
      </section>
    </main>
  );
};
